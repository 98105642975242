export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyByyNM8I33zzIxhVFBeKTBckXl05WQTE3w',
    authDomain: 'pythone-c7465.firebaseapp.com',
    projectId: 'pythone-c7465',
    storageBucket: 'pythone-c7465.appspot.com',
    messagingSenderId: '17703764684',
    appId: '1:17703764684:web:5258fa5f01954df7e4b869',
    measurementId: 'G-VD13HPYN1X',
  },
};

export const apis = {
  baseUrl: 'https://synergymb2b-api.codegenio.com/api',
  socketBaseUrl: 'https://synergymb2b-api.codegenio.com',
  socketKey: 'Bh9cxGTTakw7vKN8W1-pxqEJTd6o_ynvT90B',
  //baseUrl: 'https://web.codewithshahid.tech/api',
  // baseUrl: 'http://192.168.18.43:8000/api',
  googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
  googleCaptchaSiteKey: '',
};

export const socialLoginUrls = {
  google: `${apis.baseUrl}/public/login/google`,
  facebook: `${apis.baseUrl}/public/login/facebook`,
};

export const appURL = 'https://multi-vendor-api.codegenio.com.app';
